/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import {graphql} from 'gatsby'
import {Layout, Pagination, PostCard} from '../components/blog'
import {MetaData} from '../components/blog/meta'

type Props = {
  data: {
    ghostTag: {
      name: string
      description: string
    }
    allGhostPost: any
  }
  location: {
    pathname: string
  }
  pageContext: any
}

/**
 * Tag page (/tag/:slug)
 *
 * Loads all posts for the requested tag incl. pagination.
 *
 */
const Tag = ({data, location, pageContext}: Props) => {
  const tag = data.ghostTag
  const posts = data.allGhostPost.edges

  return (
    <>
      <MetaData data={data} location={location} type="series" />
      <Layout>
        <div className="container">
          <header className="tag-header">
            <h1>{tag.name}</h1>
            {tag.description ? <p>{tag.description}</p> : null}
          </header>
          <section className="post-feed">
            {posts.map(({node}: any) => (
              // The tag below includes the markup for each post - components/blog/post-card.tsx
              <PostCard key={node.id} post={node} />
            ))}
          </section>
          <Pagination pageContext={pageContext} />
        </div>
      </Layout>
    </>
  )
}

export default Tag

export const pageQuery = graphql`
  query GhostTagQuery($slug: String!, $limit: Int!, $skip: Int!) {
    ghostTag(slug: {eq: $slug}) {
      ...GhostTagFields
    }
    allGhostPost(
      sort: {order: DESC, fields: [published_at]}
      filter: {tags: {elemMatch: {slug: {eq: $slug}}}}
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
  }
`
